import React from "react";

function article_1() {
	return {
		date: "29 June 2024",
		title: "Making an AI Voice Assistant",
		description:
			"Creating Local and Cloud AI Voice Assistants with Python & JavaScript",
		keywords: ["AI", "Voice Assistant", "Python", "JavaScript"],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="article-text">
						<p>
							AI voice assistants are becoming increasingly
							popular in the tech industry. They are used in
							smartphones, smart home devices, and even in cars.
							In this article, I'll explain how MILES was made.
						</p>
						<p>
							MILES is an AI voice assistant that can be used on
							both local and cloud servers. I created a base
							version using the OpenAI API and the GPT-3.5 Turbo
							and GPT-4 models. The base version of MILES is a
							cloud-based voice assistant that can be accessed
							from any device with an internet connection.
						</p>
						<p>
							I built the prototype using Electron and Python. I
							used Electron to provide a neat frontend for the
							voice assistant and Python to handle the backend.
							The backend is responsible for processing the user's
							voice commands and generating responses. The
							frontend is responsible for displaying the responses
							to the user. MILES was built using a number of
							python packages with the openwakeword python package
							and a custom wake word model. The wake word model is
							used to detect when the user is speaking to MILES.
							When the wake word is detected, MILES starts
							listening for the user's command. This is done using
							the speech_recognition python package combined with
							openai whisper.
						</p>
						<p>
							Using these packages, it allows MILES to run even on
							the tiniest of machines, e.g: a Raspberry Pi. I then
							focused on making a completely local version of
							MILES to ensure there is privacy and security. To
							make this, I used a M1 2020 Macbook Air. This
							allowed me to run models locally with still a few
							seconds of latency. I originally tested with llama3
							and Ollama, but I found that the best model to use
							was Hermes 2 Pro along with LM Studio. LM Studio
							provides a local OpenAI equivalent API that can be
							used to run models locally. This allows MILES to run
							without any internet connection.
						</p>
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1];

export default myArticles;
