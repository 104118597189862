const INFO = {
	main: {
		title: "Variet",
		name: "Ellsie",
		email: "mail@variet.tech",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/ellsiecodes",
		github: "https://github.com/variet-tech",
		linkedin: "https://www.linkedin.com/in/ellsies/",
		//instagram: "https://instagram.com/",
		//stackoverflow: "https://stackoverflow.com/",
		//facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Variet - Powering the future of Voice Assistants with AI",
		description:
			"Variet.tech develops voice assistants powered by the latest AI models to help users in their daily life. Our voice assistants are designed to be user-friendly and easy to use, making them accessible to everyone. We believe that voice assistants are the future of technology and are committed to creating innovative solutions that will shape the future of voice technology. Our team is dedicated to pushing the boundaries of what's possible with voice technology and creating new and exciting experiences for users around the world.",
	},

	about: {
		title: "About Variet",
		description:
			"Variet was developed for a specific purpose. Privacy and intelligence. We believe that privacy is the most important aspect of any technology. Our prototype MILES has 2 versions. One that runs in a fully private environment in the cloud, and the other running completely locally without any internet connection. We are committed to providing the best user experience while keeping your data safe and secure. Our team is dedicated to pushing the boundaries of what's possible with voice technology and creating new and exciting experiences for users around the world.",
	},

	articles: {
		title: "Articles",
		description:
			"Read our latest articles on AI, Voice Assistants, Python, JavaScript, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
