const SEO = [
	{
		page: "home",
		description:
			"Variet.tech is an AI company dedicated to making voice assistants that adhere to the highest standards of privacy and security. Our voice assistants are designed to be user-friendly and easy to use, making them accessible to everyone. We believe that voice assistants are the future of technology and are committed to creating innovative solutions that will shape the future of voice technology. Our team is dedicated to pushing the boundaries of what's possible with voice technology and creating new and exciting experiences for users around the world.",
		keywords: [
			"Variet",
			"Variet.tech",
			"AI",
			"Voice Assistants",
			"Privacy",
			"Security",
			"User-friendly",
			"Innovative",
			"Voice Technology",
			"Experiences",
		],
	},

	{
		page: "about",
		description:
			"Variet.tech develops voice assistants powered by the latest AI models to help users in their daily life. Our voice assistants are designed to be user-friendly and easy to use, making them accessible to everyone. We believe that voice assistants are the future of technology and are committed to creating innovative solutions that will shape the future of voice technology. Our team is dedicated to pushing the boundaries of what's possible with voice technology and creating new and exciting experiences for users around the world.",
		keywords: [
			"Variet",
			"Variet.tech",
			"AI",
			"Voice Assistants",
			"User-friendly",
			"Voice Technology",
			"Experiences",
		],
	},

	{
		page: "articles",
		description:
			"Read our latest articles on AI, Voice Assistants, Python, JavaScript, and more.",
		keywords: [
			"Variet",
			"Variet.tech",
			"AI",
			"Voice Assistants",
			"Python",
			"JavaScript",
			"Articles",
		],
	},

	{
		page: "contact",
		description:
			"Contact Variet.tech for any inquiries. We will get back to you as soon as possible.",
		keywords: ["Variet", "Variet.tech", "Contact", "Inquiries"],
	},
];

export default SEO;
